<header *ngIf="this.themeConfig.theme === 'horizontal'"
        [ngClass]="desktopHeaderClass"
        (window:resize)="onResize($event)"
        (clickOutside)="''"
>
  <div class="container">
    <nav class="navbar navbar-expand-lg" [ngClass]="horizontalNavClass">
      <a (click)="scrollTo('home')" class="navbar-brand" routerLink="/">
        <img [src]="desktopLogo" alt="Koodan" />
      </a>
      <button
        (click)="collapsedCardToggle()"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
        class="navbar-toggler"
        data-target="#navbarNavDropdown"
        data-toggle="collapse"
        type="button"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse" id="navbarNavDropdown" [@collapsedCard]="collapsedCard">
        <ul class="navbar-nav justify-content-end ml-auto scrollspy">
<!--          <li class="nav-item" [ngClass]="{ active: currentSection === 'home' }" (click)="scrollTo('home')">-->
<!--            <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'home' }">-->
<!--              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-home"></i>-->
<!--              <span *ngIf="this.themeConfig.theme !== 'collapsed'">Home</span>-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="nav-item" [ngClass]="{ active: currentSection === 'about' }" (click)="scrollTo('about')">-->
<!--            <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'about' }">-->
<!--              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-user-following"></i>-->
<!--              <span *ngIf="this.themeConfig.theme !== 'collapsed'">About</span>-->
<!--            </a>-->
<!--          </li>-->
          <li
            class="nav-item" [ngClass]="{ active: currentSection === 'platform' }" (click)="scrollTo('webinars')">
            <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'webinars' }">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-briefcase"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">{{ 'nav.webinars' | translate | titlecase }}</span>
            </a>
          </li>
          <li
            *ngIf='user$ | async'
            class="nav-item" [ngClass]="{ active: currentSection === 'platform' }" (click)="scrollToWithRedirect('platform', 'platform')">
            <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'platform' }">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-briefcase"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">{{ 'nav.platform' | translate | titlecase }}</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{ active: currentSection === 'programs' }" (click)="scrollToWithRedirect('programs', '')">
            <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'programs' }">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-briefcase"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">{{ 'nav.programs' | translate | titlecase }}</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{ active: currentSection === 'curriculum' }" (click)="scrollToWithRedirect('curriculum', '')">
            <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'curriculum' }">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-graduation"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">{{ 'nav.curriculum' | translate | titlecase }}</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{ active: currentSection === 'works' }" (click)="scrollTo('works')">
            <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'works' }">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-layers"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">{{ 'nav.works' | translate | uppercase }}</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{ active: currentSection === 'blog' }" (click)="scrollTo('blog')">
            <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'blog' }">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-note"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">{{ 'nav.blog' | translate | titlecase }}</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{ active: currentSection === 'contact' }" (click)="scrollToWithRedirect('contact', '')">
            <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'contact' }">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-bubbles"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">{{ 'nav.contacts' | translate | titlecase }}</span>
            </a>
          </li>
<!--          <li *ngIf='!(user$ | async)' class="nav-item"-->
<!--              [ngClass]="{ active: currentSection === 'login' }"-->
<!--              (click)="scrollToWithRedirect('login', 'login')">-->
<!--            <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'login' }">-->
<!--              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-bubbles"></i>-->
<!--              <span class='' *ngIf="this.themeConfig.theme !== 'collapsed'">{{ 'nav.login' | translate | titlecase }}</span>-->
<!--            </a>-->
<!--          </li>-->
<!--          <li *ngIf='!!(user$ | async)' class="nav-item" (click)="logout()">-->
<!--            <a href="javascript:" class="nav-link">-->
<!--              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-bubbles"></i>-->
<!--              <span class='' *ngIf="this.themeConfig.theme !== 'collapsed'">{{ 'nav.logout' | translate | titlecase }}</span>-->
<!--            </a>-->
<!--          </li>-->
          <li class="nav-item dropdown" *ngIf="isDesktop">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-expanded="false">{{ 'nav.language' | translate }}</a>
            <div class="dropdown-menu">
              <a class="dropdown-item" (click)='onLanguageChange("et")'>Eesti</a>
              <a class="dropdown-item" (click)='onLanguageChange("us")'>English</a>
              <a class="dropdown-item" (click)='onLanguageChange("ru")'>Русский</a>
            </div>
          </li>
          <li class="nav-item dropdown text-center" *ngIf="!isDesktop">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button data-target="#navbarNavDropdown"
                      data-toggle="collapse"
                      type="button"
                      class="btn btn-secondary"
                      (click)='onLanguageChange("et"); collapsedCardToggle()'>
                EE</button>
              <button data-target="#navbarNavDropdown"
                      data-toggle="collapse"
                      type="button"
                      class="btn btn-secondary"
                      (click)='onLanguageChange("us"); collapsedCardToggle()'>
                EN</button>
              <button data-target="#navbarNavDropdown"
                      data-toggle="collapse"
                      type="button"
                      class="btn btn-secondary"
                      (click)='onLanguageChange("ru"); collapsedCardToggle()'>
                RU</button>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>

<!-- main layout -->
<main class="{{ openClass }}" [ngClass]="contentClass">
  <router-outlet></router-outlet>

  <!-- Go to top button -->
  <a href="javascript:" id="return-to-top" (click)="scrollTo('home')"><i class="fas fa-arrow-up"></i></a>
</main>
