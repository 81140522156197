<button
  type='button'
  class="btn btn-default"
  [ngClass]="disabled ? 'disabled' : ''"
  [disabled]='disabled'
  (click)='onClick()'>
  <ng-container *ngIf='isLogo'>
    <img src='assets/images/kdn_logo_tp_bg_white.png' alt='unavailable' />
  </ng-container>
  {{ label }}
</button>

