import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollSpyDirective } from '../components/scroll-spy/scroll-spy.directive';
import { KdmButtonComponent } from './components/kdm-button/kdm-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DisplayComponent } from './components/display/display.component';
import { RouterModule } from '@angular/router';
import { UiModalComponent } from './components/ui-modal/ui-modal.component';

const customComponents = [
  KdmButtonComponent,
  UiModalComponent,
];

@NgModule({
  declarations: [
    ...customComponents,
    ScrollSpyDirective,
    DisplayComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    ...customComponents,
    ScrollSpyDirective,
    TranslateModule,
    ReactiveFormsModule,
  ],
  providers: [
    ScrollSpyDirective
  ]
})
export class SharedModule {
}
