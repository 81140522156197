// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBA5j9QTFigjIP6EAzjKro4MOO4vS7pRGk',
    authDomain: 'koodan-85064.firebaseapp.com',
    projectId: 'koodan-85064',
    storageBucket: 'koodan-85064.appspot.com',
    messagingSenderId: '1011349663474',
    appId: '1:1011349663474:web:212b5349cd1eeb0cf12a6b',
    measurementId: 'G-QJPSBX3Z6T'
  },
  contentfulConfig: {
    space: 'dgbn9jde4sow',
    accessToken: 'P2IfdgS38SQTC5l73pVBySDKG3GhLzx88jATqBAIZp8',
    contentTypes: {
      blogs: 'blog',
      webinars: 'webinar',
      mentors: 'mentors'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
