import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ButtonColorsEnum } from '../../models/enums/ButtonColorsEnum';

@Component({
  selector: 'kdn-button',
  templateUrl: './kdm-button.component.html',
  styleUrls: ['./kdm-button.component.scss']
})
export class KdmButtonComponent {

  @Output() kdmClick: EventEmitter<void> = new EventEmitter();
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() isLogo: boolean;
  @Input() color: ButtonColorsEnum;

  onClick(): void {
    this.kdmClick.emit();
  }
}
