import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectService } from './shared/services/language-select.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    private languageSelect: LanguageSelectService
  ) {
    const lang = localStorage.getItem('lang');
    if (lang != null) {
      this.translate.use(lang);
    }
  }

  ngOnInit(): void {
    this.languageSelect.language.subscribe(lg => {
      this.translate.use(lg);
    });
  }
}
