import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private angularFireAuth: AngularFireAuth) {
  }

  registerWithEmailAndPassword(user: { email: string, password: string }): Promise<firebase.auth.UserCredential> {
    return this.angularFireAuth.createUserWithEmailAndPassword(user.email, user.password);
  }

  signInWithEmailAndPassword(user: { email: string, password: string }): Promise<firebase.auth.UserCredential> {
    return this.angularFireAuth.signInWithEmailAndPassword(user.email, user.password);
  }

  getUser(): Observable<firebase.User | null> {
    return this.angularFireAuth.user;
  }

  logout(): Promise<void> {
    return this.angularFireAuth.signOut();
  }
}
