import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-display',
  template: '<router-outlet></router-outlet>'
})
export class DisplayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
