import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageSelectService {

  private dataSource = new BehaviorSubject<string>('et');
  language = this.dataSource.asObservable();

  constructor() {
  }

  updateLanguage(language: string): void {
    localStorage.setItem('lang', language);
    this.dataSource.next(language);
  }

}
