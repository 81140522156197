import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollSpyService {
  counter = 'home';

  private count = new BehaviorSubject<string>(this.counter);
  section$ = this.count.asObservable();

  nextCount(value: string): void {
    this.count.next(value);
  }
}
