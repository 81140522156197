import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';

import { ScrollSpyService } from '../../components/scroll-spy/scroll-spy.service';
import { Config } from '../../app-config';
import { LanguageSelectService } from '../../shared/services/language-select.service';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('collapsedCard', [
      state(
        'collapsed, void',
        style({
          overflow: 'hidden',
          height: '0px'
        })
      ),
      state(
        'expanded',
        style({
          overflow: 'hidden',
          height: AUTO_STYLE
        })
      ),
      transition('collapsed <=> expanded', [animate('400ms ease-in-out')])
    ])
  ]
})
export class LayoutComponent implements OnInit {

  user$: Observable<firebase.User | null>;
  isDesktop: boolean;

  public currentSection: string = 'home';
  public themeConfig: any;
  public windowWidth: number;

  public openClass: string = '';
  public contentClass: string = 'content';

  public mobileHeaderClass: string = 'mobile-header-1';
  public desktopHeaderClass: string = 'desktop-header-1';
  public horizontalNavClass: string = 'navbar-dark';

  public desktopLogo = 'assets/images/logo/kdn-main-logo-tp-bg.png.png.png';

  public collapsedCard: string = 'collapsed';

  constructor(public scrollSpy: ScrollSpyService,
              private location: Location,
              private languageSelect: LanguageSelectService,
              private router: Router,
              private authService: AuthService
  ) {
    this.themeConfig = Config.config;
    this.windowWidth = window.innerWidth;

    let currentURL = this.location.path();
    const baseHref = this.location['_baseHref'];
    if (baseHref) {
      currentURL = baseHref + this.location.path();
    }
    this.themeConfig.theme = 'horizontal';
    this.themeConfig.themeType = 'light';
  }

  ngOnInit(): void {
    this.user$ = this.authService.getUser();

    this.scrollSpy.section$.subscribe((c) => {
      this.currentSection = c;
    });

    document.querySelector('body').classList.add(this.themeConfig.themeType);

    this.desktopHeaderClass = 'desktop-header-3 fixed-top';
    this.contentClass = 'content-3';
    this.horizontalNavClass = 'navbar-light';
    this.desktopLogo = 'assets/images/logo/kdn-main-logo-tp-bg.png';

    this.mobileHeaderClass = this.mobileHeaderClass + ' ' + this.themeConfig.themeType;
    this.desktopHeaderClass = this.desktopHeaderClass + ' ' + this.themeConfig.themeType;

    if (this.windowWidth > 991) {
      this.collapsedCard = 'false';
      this.isDesktop = true;
    } else {
      this.isDesktop = false;
    }
  }

  onResize(e): void {
    if (this.windowWidth > 991) {
      this.collapsedCard = 'false';
    } else {
      this.collapsedCard = 'collapsed';
    }
  }

  scrollTo(section): void {
    this.currentSection = section;
    const sectionHtml = document.querySelector('#' + section);
    if (sectionHtml !== null) {
      sectionHtml.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  }

  scrollToWithRedirect(section: string, curriculum: string): void {
    if (curriculum != null) {
      this.router.navigate([curriculum]);
    }
    const sectionHtml = document.querySelector('#' + section);
    if (sectionHtml !== null) {
      sectionHtml.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  collapsedCardToggle(): void {
    this.collapsedCard = this.collapsedCard === 'collapsed' ? 'expanded' : 'collapsed';
  }

  onLanguageChange(value: string): void {
    this.languageSelect.updateLanguage(value);
  }

  logout(): void {
    this.authService.logout()
      .then((res: any) => {
        this.scrollSpy.nextCount(this.currentSection);
        this.router.navigate(['']);
      })
      .catch((err: any) => {
        console.log('Cannot logout ' + err);
      });
  }
}
